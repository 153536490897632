<template>
  <span v-if="loadContactsError"
    class="accessWarning p-3 fw-bold h1 w-50 rounded text-center mx-auto my-auto mb-5">Uživatel nemá oprávnění</span>
  <BaseButton class="d-inline mx-auto w-25 btn-lg" @click="goHome" v-if="loadContactsError">
    Hlavní stránka
  </BaseButton>
  <div class="form-body w-100">

    <form v-if="!loadContactsError" @submit="submit" role="form">

      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <span class="h3">Letecké</span>
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="mb-2">
                <BaseButton class="d-inline m-1 btn ms-0" @click="selectAll" value="Select all"
                  :disabled="checkboxValuesLetecke.every(x => x == true)">
                  Vybrat vše
                </BaseButton>
                <BaseButton class="d-inline m-1 btn" @click="deselectAll" value="Deselect all"
                  :disabled="checkboxValuesLetecke.every(x => x == false)">
                  Zrušit vše
                </BaseButton>
              </div>

              <div class="row justify-content-start row-cols-2 my-4" id="by-air">
                <BaseCheckbox v-for=" (element, index) in numbersJsonLetecke" :label="`${element[0]} (${element[2]})`"
                  v-model="checkboxValuesLetecke[index]" :required="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <span class="h3">Pozemní</span>
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="mb-2">
                <BaseButton class="d-inline m-1 btn ms-0" @click="selectAllPozemni" value="Select all"
                  :disabled="checkboxValuesPozemni.every(x => x == true)">
                  Vybrat vše
                </BaseButton>
                <BaseButton class="d-inline m-1 btn" @click="deselectAllPozemni" value="Deselect all"
                  :disabled="checkboxValuesPozemni.every(x => x == false)">
                  Zrušit vše
                </BaseButton>
              </div>

              <div class="row justify-content-start row-cols-2 my-4" id="by-land">
                <BaseCheckbox v-for="(element, index) in numbersJsonPozemni" :label="`${element[0]} (${element[2]})`"
                  v-model="checkboxValuesPozemni[index]" :required="false" />
              </div>
            </div>
          </div>
        </div>
      </div>





      <div class="row">
        <div class="col-6">
          <BaseTextArea v-if="numbersJsonLetecke || numbersJsonPozemni" label="Text zprávy" v-model="smsText"
            :error="errors.smsText" type="text" :required="true" class="p-3" style="resize: none;" />
        </div>
        <div class="col-6 pt-2">
          <BaseButton v-if="numbersJsonLetecke || numbersJsonPozemni" type="submit" class="mt-5" @click="submit"
            value="Submit"
            :disabled="checkboxValuesLetecke.every(x => x == false) && checkboxValuesPozemni.every(x => x == false)">
            Odeslat
          </BaseButton>

          <span class="d-block successMessage p-2 fw-bold mt-4 rounded w-75 text-center h4"
            v-if="messageSendSuccess">Zpráva
            úspěšně odeslána</span>
          <span class="d-block errorMessage p-2 fw-bold mt-4 rounded w-75 text-center h5" v-if="messageSendError">Chyba
            při odesílání zprávy</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useForm, useField } from 'vee-validate'
import { ref, onMounted } from 'vue'
import * as yup from 'yup'
import ApiService from '@/services/ApiService'

export default {
  setup() {
    const getExcelsUrlLetecke = 'https://graph.microsoft.com/v1.0/sites/82efa17c-94f3-4da9-82d5-bf3caad8c655/sites/abcf5a04-1607-4f68-8bfb-09943cc6a63a/drive/root:/Repatriace/Repatria%C4%8Dn%C3%AD%20p%C5%99evozy%20-%20seznam%20l%C3%A9ka%C5%99%C5%AF%20a%20sester.xlsx:/workbook/worksheets/letecké/usedRange'
    const getExcelsUrlPozemni = 'https://graph.microsoft.com/v1.0/sites/82efa17c-94f3-4da9-82d5-bf3caad8c655/sites/abcf5a04-1607-4f68-8bfb-09943cc6a63a/drive/root:/Repatriace/Repatria%C4%8Dn%C3%AD%20p%C5%99evozy%20-%20seznam%20l%C3%A9ka%C5%99%C5%AF%20a%20sester.xlsx:/workbook/worksheets/pozemní/usedRange'

    let numbersJsonLetecke = ref('')
    let numbersJsonPozemni = ref('')
    let checkboxValuesLetecke = ref([])
    let checkboxValuesPozemni = ref([])

    let messageSendSuccess = ref(false)
    let messageSendError = ref(false)
    let loadContactsError = ref(false)

    onMounted(() => {
      if (!window.location.href.includes('access_token')) {
        ApiService.gotoLogin()
      } else {
        ApiService.getExcels(getExcelsUrlLetecke)
          .then(res => {
            console.log(res.data)
            numbersJsonLetecke.value = res.data
            numbersJsonLetecke.value = numbersJsonLetecke.value.values.slice(3).filter((element) => {
              return element[0] !== '' && element[2] !== '-' && element[2] !== ''
            })
            numbersJsonLetecke.value.forEach(element => {
              checkboxValuesLetecke.value.push(false)
            });

          })
          .catch(err => {
            loadContactsError.value = true
          })

        ApiService.getExcels(getExcelsUrlPozemni)
          .then(res => {
            console.log(res.data)
            numbersJsonPozemni.value = res.data
            numbersJsonPozemni.value = numbersJsonPozemni.value.values.slice(3).filter((element) => {
              return element[0] !== '' && element[2] !== '-' && element[2] !== ''
            })
            numbersJsonPozemni.value.forEach(element => {
              checkboxValuesPozemni.value.push(false)
            });

          })
          .catch(err => {
            loadContactsError.value = true
          })
      }

    });

    const validationSchema = yup.object({
      smsText: yup.string().required('Toto pole je povinné'),
    })

    const { handleSubmit, errors } = useForm({
      validationSchema,
    })

    const { value: smsText } = useField('smsText')

    const submit = handleSubmit(async (values, { resetForm }) => {
      let phoneNumbers = []

      checkboxValuesLetecke.value.forEach((checkbox, index) => {
        if (checkbox) {
          phoneNumbers.push(numbersJsonLetecke.value[index][2])
        }
      })

      checkboxValuesPozemni.value.forEach((checkbox, index) => {
        if (checkbox) {
          phoneNumbers.push(numbersJsonPozemni.value[index][2])
        }
      })

      values.phoneNumbers = phoneNumbers

      ApiService.sendData(values)
        .then(res => messageSendSuccess.value = true)
        .catch(err => messageSendError.value = true)
        .finally(() => {
          setTimeout(() => {
            messageSendSuccess.value = false
            messageSendError.value = false
            resetForm()
          }, 3000)
        })

      checkboxValuesLetecke.value.fill(false)
      checkboxValuesPozemni.value.fill(false)


      return false
    })

    const selectAll = function () {
      checkboxValuesLetecke.value.fill(true)
    }

    const deselectAll = function () {
      checkboxValuesLetecke.value.fill(false)
    }

    const selectAllPozemni = function () {
      checkboxValuesPozemni.value.fill(true)
    }

    const deselectAllPozemni = function () {
      checkboxValuesPozemni.value.fill(false)
    }

    const goHome = function () {
      window.location.href = '/';
    }

    return {
      handleSubmit,
      submit,
      smsText,
      errors,
      numbersJsonLetecke,
      numbersJsonPozemni,
      checkboxValuesLetecke,
      checkboxValuesPozemni,
      messageSendSuccess,
      messageSendError,
      loadContactsError,
      selectAll,
      selectAllPozemni,
      deselectAll,
      deselectAllPozemni,
      goHome
    }

  }
}

</script>

<style>
.form-body {
  box-sizing: border-box;
  width: 60vw;
  padding: 0 20px 20px;
  margin: 0 auto;
}

.label {
  color: black;
}

.filefield {
  border: 0;
}

.reqField {
  font-size: 2vh;
  color: #9E0000;
}

.successMessage {
  background-color: lightgreen;
  color: darkgreen;
}

.errorMessage {
  background-color: lightpink;
  color: red;
}

.accessWarning {
  background-color: lightyellow;
  color: darkorange;
}
</style>