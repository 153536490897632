<template>
  <!-- Footer -->
  <MDBFooter :text="['white', 'center', 'lg-start']" class="mt-auto">
    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: #0c0c72">
      © 2023 Všeobecná fakultní nemocnice v Praze, U Nemocnice 499/2, 128 08
      Praha 2, IČO: 00064165, tel.: +420 224 961 111
    </div>
    <!-- Copyright -->
  </MDBFooter>
  <!-- Footer -->
</template>

<script>
import { MDBFooter } from 'mdb-vue-ui-kit'

export default {
  name: 'Footer',
  components: {
    MDBFooter,
  },
}
</script>
