<template>
  <label v-if="label" :for="uuid" class="mt-4">
    {{ label }} <span v-if="required" class="star" style="vertical-align: middle;">*</span>
  </label>

  <BaseErrorMessage v-if="error" :id="`${uuid}-error`">
      {{ error }}
    </BaseErrorMessage>

  <textarea
    class="field"
    :rows="rows"
    :cols="cols"
    :maxlength="maxChars"
    v-bind="{
      ...$attrs,
      onInput: updateValue,
    }"
    :id="uuid"
    :value="modelValue"
    :placeholder="label"
    :aria-describedby="error ? `${uuid}-error` : null"
    :aria-invalid="error ? true : false"
    :class="{ error }"
    style="margin-bottom: 3px;"
    role="textbox"
  />
  <p style="text-align: right;">{{ modelValue.length }} z {{ maxChars }} maximálního počtu znaků</p>

</template>

<script>
import SetupFormComponent from '@/features/SetupFormComponent'
import UniqueID from '@/features/UniqueID'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false
    },
    cols: {
      type: Number,
      default: 50,
    },
    rows: {
      type: Number,
      default: 7,
    },
    maxChars: {
      type: Number,
      default: 200,
    },
    error: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  setup(props, context) {
    const { updateValue } = SetupFormComponent(props, context)
    const uuid = UniqueID().getID()

    return {
      updateValue,
      uuid,
    }
  },
}
</script>

<style scoped>
label {
  color: black;
}
.star {
   color: #9E0000;
   font-weight: bolder; /* Make it red */
   font-size: 1.5em;
}
.field {
  height: auto;
  border-radius: 10px;
  border-color: #0c0c72;
}
</style>
