<template>

    <BaseErrorMessage
      v-if="error"
      :id="`${uuid}-error`"
    >
      {{ error }}
    </BaseErrorMessage>
<div>

  <input
  v-bind="{ ...$attrs, onChange: updateValue }"
  :checked="modelValue"
  :id="uuid"
  type="checkbox"
  class="field my-2"
  role="checkbox"
  />
  <label
  :for="uuid"
  v-if="label"
  class="fw-normal h5"
  :class="{ error }"
  >
  {{ label }} <span v-if="required" class="star">*</span>
</label>
</div>

</template>

<script>
import UniqueID from '@/features/UniqueID'
import SetupFormComponent from '@/features/SetupFormComponent'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean
    },
    error: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const uuid = UniqueID().getID()
    const { updateValue } = SetupFormComponent(props, context)

    return {
      updateValue,
      uuid
    }
  }
}
</script>

<style scoped>

label {
  color: black;
}

.field {
  background-color: red;
}
.star {
  color: #9E0000;
  font-weight: bolder; /* Make it red *//* Make it red */
}
</style>
