import { createApp } from 'vue'
import App from './App.vue'
import Emitter from 'tiny-emitter'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
// import router from './router'

import 'mdb-vue-ui-kit/css/mdb.min.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';


import mitt from 'mitt'
const emitter = mitt()

const requireComponent = require.context(
 './components',
 false,
 /Base[A-Z]\w+\.(vue|js)$/
)

const app = createApp(App)

requireComponent.keys().forEach(fileName => {
 const componentConfig = requireComponent(fileName)

 const componentName = upperFirst(
   camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
 )

 app.component(componentName, componentConfig.default || componentConfig)
})

app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emitter()

app.use(store).provide('emitter', emitter).mount('#app')