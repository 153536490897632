import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_CLIENT_ID,
          authority: process.env.VUE_APP_AUTHORITY,
          redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
          postLogoutRedirectUri: '/',
          scopes: [`${process.env.VUE_APP_SCOPE_URL}test`],
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      accessToken: '',
    }
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token
    },
  },
})

export default store
