<template>
  <h5
    aria-live="assertive"
    class="errorMessage"
    :id="id"
  >
    <slot />
</h5>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style scoped>
h5 {
  font-size: 2vh;
  font-weight: bold;
}
</style>