import axios from 'axios'

const tenantId = "0f277086-d4e0-4971-bc1a-bbc5df0eb246";
const loginUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=6a1fa43a-f514-44af-9bc4-f60a471d67d8&response_type=token&redirect_uri=${encodeURIComponent(window.location.origin)}&response_mode=fragment&scope=User.Read%20Sites.Read.All%20Group.Read.All&state=12345`
const sendDataUrl = 'https://prod-14.northeurope.logic.azure.com:443/workflows/455a7398bab64bc390c0465fe9552e9f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=o11zBGc73in9lFxef6pssj__IdNJzJiZqEHSy4plGDc'
const queueName = 'kisso2'
const applicationName = 'Repatriace'

class ApiService {
  static gotoLogin() {
    if (!window.location.href.includes('access_token')) {
      window.location.href = loginUrl
    }
  }
  
  static getExcels(url) {
    const urlParams = new URLSearchParams(window.location.hash)
    let token = urlParams.get('#access_token')

    return axios.get(url,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
  }

  static sendData(formData) {

    const urlParams = new URLSearchParams(window.location.hash)
    const token = urlParams.get('#access_token')
    const tokenPayload = token.split('.')[1]
    const decodedString = atob(tokenPayload)
    const info = JSON.parse(decodedString).unique_name

    let messageData = {
      "address": formData.phoneNumbers,
      "message": formData.smsText,
      "queuename": queueName,
      "priority": 100,
      "applicationname": applicationName,
      "info": info 
    }

    return axios.post(sendDataUrl, messageData,
      {
        headers: {
          'content-type': 'application/json',
        },
      })
  }
}

export default ApiService
