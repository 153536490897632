<template>
  <!-- Image and text -->
  <MDBNavbar dark class="mb-5" style="background-color: #0c0c72" container>
    <MDBNavbarBrand href="/" style="font-size: 2vh" class="ms-4">
      Repatriace
    </MDBNavbarBrand>

    <MDBNavbarNav right class="mb-2 mb-lg-0">
      <MDBNavbarItem
        style="font-size: 3vh; color: #fff; cursor: pointer"
        target="_blank"
        rel="noopener noreferrer"
        active
      >
        <img :src="logo" height="60" alt="Logo VFN Praha" loading="lazy" role="img" class="me-4 my-2"/>
      </MDBNavbarItem>
    </MDBNavbarNav>
  </MDBNavbar>
</template>

<script>
import {
  MDBIcon,
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdb-vue-ui-kit'

export default {
  name: 'Navbar',
  components: {
    MDBIcon,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
  },
  data() {
    return {
      logo: require('@/assets/logo-VFN.svg'),
    }
  },
}
</script>

<style scoped></style>
